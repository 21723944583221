<template>

  <el-form
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="row g-9 mb-7">

          <div class="col-md-12 fv-row">
            <label class="required fs-6 fw-bold mb-2">Publish Key</label>
            <el-form-item prop="publish_key" :rules="rules['field']">
              <el-input v-model="objData.publish_key" placeholder="Publish Key"></el-input>
            </el-form-item>
          </div>
          <div class="col-md-12 fv-row">
            <label class="required fs-6 fw-bold mb-2">Subscribe Key</label>
            <el-form-item prop="subscribe_key" :rules="rules['field']">
              <el-input v-model="objData.subscribe_key" placeholder="Subscribe Key"></el-input>
            </el-form-item>
          </div>

          <div class="col-md-12 fv-row">
            <label class="required fs-6 fw-bold mb-2">Secret Key</label>
            <el-form-item prop="secret_key" :rules="rules['field']">
              <el-input v-model="objData.secret_key" placeholder="Secret Key"></el-input>
            </el-form-item>
          </div>

        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, ref, toRefs,watch,onMounted} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import router from "@/router";
import {APIs} from "@/store/enums/ApiEnums";
import {getCities} from "@/core/helpers/remote_data";
import ImageComp from "@/components/admin/general/ImageComp";
import { useRouter } from 'vue-router'
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "pubnub-setting-comp",
  components: {ImageComp},
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const {type} = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref([]);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Pubnub Setting", ['Settings']);
    });
    objData.value = {
      publish_key: '',
      subscribe_key: '',
      secret_key : ''
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      try {
        response = await ApiAxiosService.get(APIs.SETTING.pubnub_setting);
        objData.value = response.data.data;
      } catch (e) {
        handleError(e)
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          updateResource();

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      ApiAxiosService.post(APIs.SETTING.pubnub_setting , formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }


    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      updateResource,
      type,
      isIndeterminate,
      languages
    };
  },

});
</script>

